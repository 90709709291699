<template>
  <div class="row">
    <div class="col">
      <div class="col">
        <input type="text" class="form-control" v-model="searchText" placeholder="i.e AL–WATHBA, user@mywhoosh.com" />
      </div>
    </div>
    <div class="col-auto">
      <DatePicker />
    </div>
    <div class="col-auto">
      <button class="btn btn-primary" @click="submitFilter">Filter</button>
    </div>
  </div>
</template>
<script>
import DatePicker from "@/components/analytics/shared/DatePicker.vue";
export default {
  props: ["filterRiderList"],
  components: { DatePicker },
  data() {
    return {
      searchText: ""
    }
  },
  methods: {
    submitFilter() {
      this.filterRiderList(this.searchText);
    }
  }
};
</script>