<template>
  <form>
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <date-range-picker
          v-model="dateRange"
          :autoApply="autoApply"
          @update="update"
          ref="picker"
          class="widgt-datepicker"
        >
          <template v-slot:input="picker">
            {{ picker.startDate | date }} - {{ picker.endDate | date }}
          </template>
        </date-range-picker>
      </div>
    </div>
  </form>
</template>
<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: { DateRangePicker },
  data() {
    return {
      autoApply: true,
      dateRange: {
        startDate: moment().subtract(1, "days"),
        endDate: moment(),
      },
    };
  },

  mounted() {
    this.update();
  },

  filters: {
    date: function (value) {
      if (value) {
        return moment(value).format("MM/DD/YYYY");
      }
    },
  },
  methods: {
    update() {
      this.$parent.$emit('setDateRange', this.dateRange);
    },
  },
};
</script>
<style scoped>
.vue-daterange-picker {
  width: 100%;
}

@media (min-width: 992px) {
  .widgt-datepicker >>> .calendars {
    display: flex !important;
    flex-wrap: nowrap !important;
  }
}
.widgt-datepicker >>> .reportrange-text {
  padding: 14px 20px;
  font-weight: bold;
  text-align: center;
}
</style>