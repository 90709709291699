<template>
  <div class="card card-stats mv--4">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h4 class="card-title text-uppercase text-muted mb-0">{{label}}</h4>
               <p class="mt-0 mb-2 text-sm text-muted">
                  <small class="text-nowrap">{{infoText}}</small>
              </p>
              <span class="h2 font-weight-bold mb-0">{{value}}</span>
            </div>
            <div class="col-auto">
              <div class="icon icon-shape text-white rounded-circle shadow" :class="iconBGClass">
                <i :class="this.iconClass"></i>
              </div>
            </div>
          </div>
         
        </div>
      </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    value: [String, Number],
    iconBGClass: {
      type: String,
      default: "bg-gradient-red"
    },
    iconClass: {
      type: String,
      default: "ni-active-40"
    },
    infoText: {
      type: String,
      default: ""
    }
  }
}
</script>