<template>
  <div class="row">
    <div class="col">
      
        <div class="row">
          <div class="col">
            <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col-4">
                  <h3 class="mb-0">Players</h3>
                </div>
                <div class="col text-right">
                  <SearchBox @setDateRange="setDateRange" :filter-rider-list="filterRiderList" />
                </div>
              </div>
            </div>
          </div>
          </div>
       </div>

        
          <div class="row">
          <div class="col">
            <StatCard
              label="Playfab Verfied Riders"
              :value="playfabVerifiedUsers"
              iconClass="fas fa-users"
              iconBGClass="bg-gradient-red"
              infoText="Playfab verified Riders Count"
            />
          </div>
          <div class="col">
            <StatCard
              label="Playfab Imported Riders"
              :value="playfabImportedUsers"
              iconClass="fas fa-users"
              iconBGClass="bg-gradient-info"
              infoText="Playfab all imported riders"
            />
          </div>
          <div class="col">
            <StatCard
              label="Game verified riders"
              :value="gameVerifiedUsers"
              iconClass="fas fa-users"
              iconBGClass="bg-gradient-orange"
              infoText="Game verified riders count"
            />
          </div>
          <div class="col">
            <StatCard
              label="Total Riders"
              :value="totalRiders"
              iconClass="fas fa-users"
              iconBGClass="bg-gradient-info"
              infoText="Total riders count including game and playfab"
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <StatCard
              label="Active Players"
              :value="riderStats.total"
              iconClass="fas fa-users"
              iconBGClass="bg-gradient-green"
              :infoText="getActiveUserMeta()"
            />
          </div>
          <div class="col">
            <StatCard
              label="Player Ridden"
              :value="riderCount.week"
              iconClass="fas fa-biking"
              iconBGClass="bg-gradient-info"
              infoText="In last week"
            />
          </div>
          <div class="col">
            <StatCard
              label="Player Ridden"
              :value="riderCount.month"
              iconClass="fas fa-biking"
              iconBGClass="bg-gradient-orange"
              infoText="In last 30 days"
            />
          </div>
          <div class="col">
            <StatCard
              label="New Register Players"
              :value="registerRiderCount"
              iconClass="fas fa-users"
              iconBGClass="bg-gradient-info"
              infoText="In last 24 hours"
            />
          </div>
        </div>

      <div class="row">
        <div class="col">
           <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col-4">
                  <h3 class="mb-0">List of players ({{getActiveUserMeta()}})</h3>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table align-items-center table-flush table-striped">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Username</th>
                    <th>Route/Map</th>
                    <th>Power</th>
                    <th>Weight</th>
                    <th>Speed</th>
                    <th>Max RPM</th>
                    <th>Heartrate</th>
                    <th>Distance</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, k) in riderStats.list" :key="k" >
                    <td>{{ parseInt(k.toString()) + 1 }}</td>
                    <td>{{ user.Username }}</td>
                    <td>{{ user.RouteName }}</td>
                    <td>{{ user.Power }}</td>
                    <td>{{ user.PlayerWeight }}</td>
                    <td>{{ user.Speed }}</td>
                    <td>{{ user.MaxRPM }}</td>
                    <td>{{ user.HeartRate }}</td>
                    <td>{{ user.Distance }}</td>
                    <td>
                      <button class="btn btn-primary btn-sm" v-if="0">View</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer d-flex justify-content-center">
              <button class="btn btn-primary btn-md" @click="loadMore" :disabled="loadDisable">Load More...</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import StatCard from "@/components/analytics/stats/Card";
import SearchBox from "@/components/analytics/shared/Search.vue";
//import Paginate from "vuejs-paginate";

export default {
  components: { StatCard, SearchBox, /* Paginate */ },
  data() {
    return {
      size: 5,
      total: 0,
      loadCount: 1,
      searchText: null,
      loadDisable: false,
      lastRiderCount: 0,
      setActiveUserMeta: false,
      dateRange: {
        startDate: "",
        endDate: "",
      }
    }
  },
  mounted() {
    this.getRidersStateList(5);
    this.getRidersCount();
  },

  computed: {
    ...mapGetters('analytics', ['riderCount', 'riderStats', 'registerRiderCount', 'allRidersCount']),
    
    playfabVerifiedUsers() {
      return this.allRidersCount.playfabVerifiedUsers;
    },

    playfabImportedUsers() {
      return this.allRidersCount.playfabImportedUsers;
    },

    gameVerifiedUsers() {
      return this.allRidersCount.gameVerifiedUsers;
    },

    totalRiders() {
      return this.allRidersCount.total;
    }
  },

  methods: {
    async getRidersStateList(size) {
      this.$store.commit('common/SET_LOADING', true);

      await this.$store.dispatch('analytics/getRiderStats', {
        size: size || this.size,
        dateRange: this.dateRange,
        searchText: this.searchText
      });

      await this.$store.dispatch('analytics/getRegisterRiderCount', {
        startDate: moment(this.dateRange.startDate).unix(),
        endDate: moment(this.dateRange.endDate).unix()
      });

      this.$store.commit('common/SET_LOADING', false);
    },

    async getRidersCount() {
      this.$store.commit('common/SET_LOADING', true);

      await this.$store.dispatch('analytics/getAllRiderCount');

      await this.$store.dispatch('analytics/getRiderCount', {
        size: 1,
        dateRange: {
          startDate: this.getDate(),
          endDate: this.getDate(1),
        },
        searchText: null,
        type: "day"
      });

      await this.$store.dispatch('analytics/getRiderCount', {
        size: 1,
        dateRange: {
          startDate: this.getDate(),
          endDate: this.getDate(7),
        },
        searchText: null,
        type: "week"
      });

      await this.$store.dispatch('analytics/getRiderCount', {
        size: 1,
        dateRange: {
          startDate: this.getDate(),
          endDate: this.getDate(30),
        },
        searchText: null,
        type: "month"
      });

      this.$store.commit('common/SET_LOADING', false);
    },

    getDate(day) {
      if (day) {
        return moment().subtract(day, "days");
      }

      return moment();
    },

    setDateRange(date) {
      this.dateRange = date;
    },

    filterRiderList(searchText) {
      this.setActiveUserMeta = true;
      this.searchText = searchText || null;
      this.getRidersStateList(5);
    },

    loadMore() {
      const riderList = this.riderStats.list;

      if (this.lastRiderCount === riderList.length) {
        this.loadDisable = true;

        return;
      } else {
        this.lastRiderCount = riderList.length;
      } 

      this.loadCount++;
      this.getRidersStateList(this.size * this.loadCount);
    },

    dateFormat(value) {
      return moment(value).format("MM/DD/YYYY");
    },

    getActiveUserMeta() {
      if (this.setActiveUserMeta) {
        return `${this.dateFormat(this.dateRange.startDate)} - ${this.dateFormat(this.dateRange.endDate)}`;
      }

      return "In last 24 hours";
    }
  }
};
</script>
<style scoped>
</style>